* {
  font-family: 'Open Sans', sans-serif;
  color: black;
  scroll-behavior: smooth;
}

.container > .row {
  margin-left: 0;
  margin-right: 0;
}

.container > .row > .col {
  padding-left: 0;
  padding-right: 0;
}

.container-fluid > .row {
  margin-left: 0;
  margin-right: 0;
}

.container-fluid > .row > .col {
  padding-left: 0;
  padding-right: 0;
}

.faq {
  box-shadow: 0 0 5px gray;
  margin-bottom: 6px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.faq:hover {
  box-shadow: 0 0 10px #00509b;
}

.faq-header {
  background-color: #ffffff;
  padding: 18px;
  cursor: pointer;
}

.faq-title {
  float: left;
  font-size: 25px;
  font-weight: 300;
  color: #00509b;
}

.faq-arrow {
  float: right;
  height: 37px;
}

.rotate {
  transform: rotate(180deg);
}

.faq-content {
  padding: 18px;
  background-color: white;
}

.menutile {
  padding: 0 20px;
  font-weight: 600;
  line-height: 50px;
  text-align: start;
  background-color: #3f4348;
  box-shadow: 0 0 5px gray;
  border-bottom: solid 1px #7e868f;
}

.menutile:hover {
  background-color: #565c63;
}

.menutile p {
  margin-bottom: 0 !important;
}